
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { formatMoney } from "../../../utils/currencyFormat";

import SupplierItemTracking from "./SupplierItemTracking";

const SupplierItem = ({
  line = {},
  poState = {},
  index = 0,
  changePOLine,
  changePOLine_date,
  changePOLine_checkbox,
  onClickAddTracking,
  onChangeTrackingInfo,
  onChangeTrackingInfo_date,
  onClickRemoveTracking,
  onClickConfirmLine,
}) => {
  const {
    poLineNo = 0,
    itemID = "",
    itemDesc = "",
    extendedItemDesc = "",
    supplierPartNo = "",
    supplierOrderNumber = "",
    remainingQty = 0,
    qtyOrdered = 0,
    qtyReceived = 0,
    UOM = "",
    unitPrice = 0,
    unitSize = "",
    pricingUnit = "",
    pricingUnitSize = "",
    contractNumber = null,
    supplierShipped = false,
    acknowledged = false,
    complete = false,
    requiredDate = null,
    acknowledgedDate = null,
    expectedShipDate = null,
    _trackingInfo = "",
    deleteFlag = false,
  } = line;

  const {
    spoxEnabled = false
  } = poState;

  const _lineStatus = () => {
    if (deleteFlag) {
      return (
        <Fragment>
          <span style={{ fontWeight: "bold" }}>Line Canceled</span>
        </Fragment>
      );
    }
    if (complete) {
      return <Fragment>Line Complete</Fragment>;
    }

    if (qtyReceived > 0 && remainingQty > 0) {
      return <Fragment>Line Acknowledged, Partial Items Received</Fragment>;
    }

    if (supplierShipped) {
      return <Fragment>Line Acknowledged, Shipped</Fragment>;
    }
    if (acknowledged) {
      return <Fragment>Line Acknowledged, Awaiting Shipment</Fragment>;
    } else {
      return <Fragment>Unacknowledged Line</Fragment>;
    }
  };

  return (
    <div className="po-item">
      <div className="po-item-hdr">
        <div
          className={
            deleteFlag ? "itemName-outer canceledItem" : "itemName-outer"
          }
        >
          <div><b>Line #{poLineNo}</b></div>
          {!deleteFlag && (
            <Fragment>
              <div><b>Ship ASAP unless instructed otherwise</b></div>
              <div>
                <span>Requested Delivery Date: </span>{" "}
                <b>{moment(requiredDate).format("MM/DD/YY")}</b>
              </div>
            </Fragment>
          )}
          {!deleteFlag && supplierPartNo !== null && supplierPartNo !== "" && (
            <div>
              <span>Contract No: </span>
              <b>{contractNumber}</b>
            </div>
          )}
          <div>
            {deleteFlag && <Fragment>Line Canceled</Fragment>}
            {!deleteFlag && complete && <Fragment>Line Complete</Fragment>}
            {!deleteFlag && !complete && acknowledged && (
              <Fragment>
                {moment(acknowledgedDate).isValid() ? (
                  <Fragment>
                    <span>
                      Acknowledged On:{" "}
                      <b>{moment(acknowledgedDate).format("MM/DD/YY")}</b>
                    </span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <b>Line Acknowledged</b>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div className="itemStatus">{_lineStatus()}</div>
      </div>
      {deleteFlag ? (
        <Fragment></Fragment>
      ) : (
        <Fragment>
          <div className="overall-lines-flex">
            <div>
              <span>Supplier Order No:</span>{" "}
              <input
                className="supplierOrderNo"
                type="text"
                name="supplierOrderNumber"
                value={
                  supplierOrderNumber === null || supplierOrderNumber === ""
                    ? ""
                    : supplierOrderNumber
                }
                onChange={(e) => changePOLine(e, index)}
              />
            </div>
            <div>
              <span>Estimated Ship Date: </span>
              {complete && <Fragment>Complete</Fragment>}
              {!complete && supplierShipped && <Fragment><b>Shipped</b></Fragment>}
              {!complete && !supplierShipped && (
                <Fragment>
                  <div className="customDatePicker-outer">
                    <DatePicker
                      className="customDatePicker"
                      selected={
                        moment(expectedShipDate).isValid()
                          ? new Date(
                            moment(expectedShipDate).format("MM/DD/YY")
                          )
                          : null
                      }
                      dateFormat="MM/dd/yyyy"
                      closeOnScroll={true}
                      onChange={(date) =>
                        changePOLine_date(date, index, "expectedShipDate")
                      }
                      minDate={(new Date(poState.orderDate))}
                    />
                  </div>
                </Fragment>
              )}
            </div>
            {spoxEnabled && (
              <div class="boxes ackLineOptions">
                <input type="checkbox" className="styled-checkbox-important"
                  id={poLineNo}
                  value="true"
                  checked={acknowledged}
                  name="_acknowledgeLine"
                  onClick={(e) => onClickConfirmLine(index, e.target.checked)}
                />
                <label for={poLineNo}>Acknowledge Line</label>
            </div>)}
          </div>

          <div id="poLineTable">
            <table>
              <thead>
                <tr>
                  {supplierPartNo !== null && supplierPartNo !== "" && (
                    <Fragment>
                      <th>Supplier Part No</th>
                    </Fragment>
                  )}
                  <th>Item ID</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Size</th>
                  <th>Price</th>
                </tr>
                <tr>
                  {supplierPartNo !== null && supplierPartNo !== "" && (
                    <Fragment>
                      <td className="fontWeightBold">{supplierPartNo}</td>
                    </Fragment>
                  )}
                  <td className="fontWeightBold">{itemID}</td>
                  <td className="fontWeightBold itemDescription">{itemDesc}</td>
                  <td className="fontWeightBold">{qtyOrdered} {UOM}</td>
                  <td className="fontWeightBold">{unitSize} {UOM}</td>
                  <td className="fontWeightBold">${formatMoney(unitPrice)} /{" "}
                    {pricingUnitSize} {pricingUnit}</td>
                </tr>
                {extendedItemDesc !== null && extendedItemDesc !== "" && (
                  <Fragment>
                    <tr>
                      <td colSpan="6" className="fontWeightNormal padding-top-10"><span className="textUnderline">Ext Description:</span><br /> {extendedItemDesc}</td>
                    </tr>
                  </Fragment>
                )}
              </thead>
            </table>
          </div>
          <br />
          {(acknowledged || !spoxEnabled) && (
            <Fragment>
              <div className="po-item-track-section">
                <div className="sectionTitle">Shipment Information</div>
                <div className="po-item-track-inner">
                  {_trackingInfo.map((shipment, shipmentIndex) => {
                    let { key: shipmentKey = "" } = shipment;

                    if (shipment._id !== null && shipment._id !== "") {
                      shipmentKey = shipment._id;
                    }

                    return (
                      <SupplierItemTracking
                        key={shipmentKey}
                        line={line}
                        poState={poState}
                        lineIndex={index}
                        shipmentIndex={shipmentIndex}
                        shipment={shipment}
                        addItem={false}
                        onClickAddTracking={onClickAddTracking}
                        onChangeTrackingInfo={onChangeTrackingInfo}
                        onChangeTrackingInfo_date={onChangeTrackingInfo_date}
                        onClickRemoveTracking={onClickRemoveTracking}
                      />
                    );
                  })}
                  <SupplierItemTracking
                    line={line}
                    poState={poState}
                    lineIndex={index}
                    addItem={true}
                    onClickAddTracking={onClickAddTracking}
                    onChangeTrackingInfo={onChangeTrackingInfo}
                    onChangeTrackingInfo_date={onChangeTrackingInfo_date}
                    onClickRemoveTracking={onClickRemoveTracking}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

SupplierItem.propTypes = {
  line: PropTypes.object.isRequired,
  poState: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  changePOLine: PropTypes.func.isRequired,
  changePOLine_date: PropTypes.func.isRequired,
  changePOLine_checkbox: PropTypes.func.isRequired,
  onClickAddTracking: PropTypes.func.isRequired,
  onChangeTrackingInfo: PropTypes.func.isRequired,
  onChangeTrackingInfo_date: PropTypes.func.isRequired,
  onClickRemoveTracking: PropTypes.func.isRequired,
  onClickConfirmLine: PropTypes.func.isRequired,
};

export default SupplierItem;
