import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Spinner from "../../../layout/Spinner";

const Supplier_emailPopup = ({
  supplierPO = {},
  emailMsgState = {},
  poState = {},
  onChangeEmailBuyerFields,
  closeEmailPopup,
  sendEmailFromPopup,
}) => {
  const { subject = "", msg = "", from = "", errorMsg = "" } = emailMsgState;
  const {
    emailSending = false,
    emailSent = false,
    emailError = false,
  } = supplierPO;
  const { buyerName = "" } = poState;

  return (
    <div className="supplierPO overlay-email-outer">
      <div className="supplierPO overlay-email">
        <div className="close" onClick={() => closeEmailPopup()}>
          <i className="far fa-times-circle"></i>
        </div>
        <div className="title">Request PO Changes From Stellar Industrial</div>
        <div className="instructions">
          Send an email directly to {buyerName} about this purchase order.
        </div>
        {emailSending ? (
          <Fragment>
            <div style={{ padding: "20px 0px" }}>
              <Spinner />
              <br />
              Sending your email.
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {emailError && (
              <Fragment>
                <div style={{ padding: "20px 0px" }}>
                  <span
                    style={{ fontSize: "90px", color: "red", opacity: ".5" }}
                  >
                    <i
                      className="fas fa-exclamation-circle"
                      style={{ marginBottom: "30px" }}
                    ></i>
                  </span>
                  <br />
                  There was a problem sending your email. Please try again
                  later.
                </div>
              </Fragment>
            )}
            {emailSent && (
              <Fragment>
                <div style={{ padding: "20px 0px" }}>
                  <span
                    style={{ fontSize: "90px", color: "green", opacity: ".5" }}
                  >
                    <i
                      className="far fa-check-circle"
                      style={{ marginBottom: "30px" }}
                    ></i>
                  </span>
                  <br />
                  Your Email Has Been Sent
                </div>
              </Fragment>
            )}
            {!emailError && !emailSent && (
              <Fragment>
                <div className="text alignLeft">
                  <div>
                    <b>To Stellar Buyer:</b> {buyerName}
                    <br />
                    <input
                      type="text"
                      name="from"
                      value={from}
                      placeholder="Your Email Address"
                      onChange={(e) => onChangeEmailBuyerFields(e)}
                    />
                    <br />
                    <input
                      type="text"
                      name="subject"
                      value={subject}
                      placeholder="Subject Line"
                      onChange={(e) => onChangeEmailBuyerFields(e)}
                    />
                    <br />
                    <textarea
                      name="msg"
                      value={msg}
                      placeholder="Message To Buyer"
                      onChange={(e) => onChangeEmailBuyerFields(e)}
                    ></textarea>
                  </div>
                </div>
                {errorMsg !== null && errorMsg !== "" && (
                  <div style={{ marginBottom: "10px", color: "red" }}>
                    {errorMsg}
                  </div>
                )}

                <button
                  className="acknowledgeBtn"
                  onClick={() => sendEmailFromPopup()}
                >
                  Send Email
                </button>
                <button onClick={() => closeEmailPopup()}>Cancel</button>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

Supplier_emailPopup.propTypes = {
  supplierPO: PropTypes.object.isRequired,
  emailMsgState: PropTypes.object.isRequired,
  poState: PropTypes.object.isRequired,
  onChangeEmailBuyerFields: PropTypes.func.isRequired,
  closeEmailPopup: PropTypes.func.isRequired,
  sendEmailFromPopup: PropTypes.func.isRequired,
};

export default Supplier_emailPopup;
