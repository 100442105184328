import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Spinner from "../../../layout/Spinner";

import AckChecklist from "./Ack_Checklist";
import SupplierHDR from "./Supplier_hdr";
import SupplierHdrShipping from "./Supplier_hdrShipping";
import SupplierItems from "./SupplierItems";
import SupplierSaveBar from "./Supplier_saveBar";
import SupplierEmailPopup from "./Supplier_emailPopup";

import "react-datepicker/dist/react-datepicker.css";
import "./SupplierPO.css";
import "../../../layout/CustomCSS/fancyMiniToggle.scss";
import "./../../../layout/CustomCSS/reactAlerts.scss"
import ChangePageTitle from "../../../layout/Common/ChangePageTitle";

import {
  getPODetails,
  savePODetails,
  emailPOBuyer,
  clearEmailPOBuyer,
} from "../../../../actions/supplierPO";
import { setAlert } from "../../../../actions/alert";

const SupplierPO = ({
  params,
  supplierPO = {},
  getPODetails,
  savePODetails,
  setAlert,
  emailPOBuyer,
  clearEmailPOBuyer,
}) => {
  const [poState, setpoState] = useState({
    deleteFlag: false,
    saveError: false,
    saveComplete: false,
    _updatingP21Flag: false
  });

  const [saveButtonState, setSaveButtonState] = useState({ display: false });

  //unchanged, modified
  const [formState, setFormState] = useState("unchanged");
  
  const [emailMsgState, setEmailMsgState] = useState({
    displayPopup: false,
    from: "",
    subject: "",
    msg: "",
    errorMsg: "",
  });

  const {
    loading = true,
    loadError = false,
    saveError = false,
    saveComplete = false,
    poData = {},
    savingToP21 = false,
  } = supplierPO;

  //get the intial details for this PO
  useEffect(() => {
    setSaveButtonState({ ...saveButtonState, display: false });
    setFormState("unchanged");

    getPODetails(params);
    // eslint-disable-next-line
    
  }, []);

  //provide for pulling up a PO that is in the middle of saving
  useEffect(() => {
    if ((!loading && !loadError)) {
      setSaveButtonState({ ...saveButtonState, display: false });
      setFormState("unchanged");

      setpoState(poData);
    }
    // eslint-disable-next-line
  }, [poData]);

  // The effect where we show an exit prompt, but only if the formState is NOT
  // unchanged. When the form is being saved, or is already modified by the user,
  // sudden page exit could be a destructive action. Our goal is to prevent that.
  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => {};
  }, [formState]);

  // if there is a save error, then refresh the data because it is not displaying 
  // the proper data
  useEffect(() => {
    if (saveComplete) {
      //console.log("PO Data loading into local state");
      setSaveButtonState({ ...saveButtonState, display: false });
      setFormState("unchanged");

      let title = "";
      let message = "";
      if (saveError) {
        title = "Changes Queued for Submittal";
        message = "Thank you so much, the changes have been saved and queued to be sent to Stellar's system.";
      } else {
        title = "Changes Saved Successfully";
        message = "Thank you so much, the changes have been saved and sent to Stellar's system.";
      }

      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: "OK",
          },
        ],
      });

      getPODetails(params, true);
    }
    // eslint-disable-next-line
  }, [loading, savingToP21, poState]);

  const savePO = (e) => {
    //1st see if there are any lines where they have acknowledged but have not set the Estimated Shipping Date - Require Estimated Shipping Date
    let noEstShipDates = 0;
    if (poState.spoxEnabled) {
      noEstShipDates = poState.lines
        .filter((el) => el.acknowledged)
        .filter((el) => el.complete === false)
        .filter((el) => el.deleteFlag === false)
        .filter((el) => el.expectedShipDate === null);

      if (noEstShipDates.length > 0) {
        confirmAlert({
          title: "Information Required",
          message:
            "Please enter an estimated ship date for ALL lines you have acknowledged.",
          buttons: [
            {
              label: "Go Back",
            },
          ],
        });
        return;
      }
    }

    //2nd see if there are any lines where the Estimated Ship Date is entered but the line is not acknowledged - Require Acknowledged
    let noAcknowledgements = 0;
    if (poState.spoxEnabled) {
      noAcknowledgements = poState.lines
        .filter((el) => !el.acknowledged)
        .filter((el) => el.complete === false)
        .filter((el) => el.deleteFlag === false)
        .filter((el) => el.expectedShipDate !== null);

      if (noAcknowledgements.length > 0) {
        confirmAlert({
          title: "Information Required",
          message:
            "Please acknowledge ALL lines that have an Estimated Ship Date.",
          buttons: [
            {
              label: "Go Back",
            },
          ],
        });
        return;
      }
    }

    //3rd see if there are any lines where Supplier Order No is entered but the line is not acknowledged and/or no estimated ship date - Require Estimated Ship Date and Acknowledged
    let noInfoForSupplierNo = 0;
    if (poState.spoxEnabled) {
      noInfoForSupplierNo = poState.lines
        .filter((el) => !el.acknowledged)
        .filter((el) => el.complete === false)
        .filter((el) => el.deleteFlag === false)
        .filter((el) => el.expectedShipDate === null)
        .filter((el) => ((el.supplierOrderNumber !== "") || (el.supplierOrderNumber !== null)));

      if (noInfoForSupplierNo.length > 0) {
        confirmAlert({
          title: "Information Required",
          message:
            "Please enter an estimated ship date and acknowledge ALL lines that have a Supplier Order Number.",
          buttons: [
            {
              label: "Go Back",
            },
          ],
        });
        return;
      }
    }
        
    savePODetails(poState, params);

    //if the user gets here save down
    setSaveButtonState({ ...saveButtonState, display: false });
    setFormState("unchanged");

    return;
  };

  const confirmShipToFunc = (e) => {
    //block since this is a one way
    if (!poState[e.target.name] === false) {
      return;
    }

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    setpoState({
      ...poState,
      _shipToConfirmed: true,
      _agreedToTerms: true,
    });
  };

  const changePOLine = (e, _index) => {
    //since we are doing a deep edit on the state
    //do the edit out side of state then reinject with a setFormState call
    let tempState = poState.lines;
    if (
      tempState[_index] === undefined ||
      tempState[_index][e.target.name] === undefined
    )
      return;

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");
    tempState[_index][e.target.name] = e.target.value;

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const changePOLines = (_field, _value) => {
    //since we are doing a deep edit on the state
    //do the edit out side of state then reinject with a setFormState call
    let tempState = poState.lines;
    tempState.forEach((line, _lineIndex) => {
      //make sure the date being entered isn't before the order date of this PO
      if (
        tempState[_lineIndex] !== undefined ||
        tempState[_lineIndex][_field] !== undefined) 
      {
        tempState[_lineIndex][_field] = _value;
      }     
    });

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    setpoState({
      ...poState,
      lines: tempState,
    });
  };
  
  const changePOLine_date = (date, _index, _name) => {
    //check that our date is not before the order date
    const orderDate = poState.orderDate;

    //since we are doing a deep edit on the state
    //do the edit out side of state then reinject with a setFormState call
    let tempState = poState.lines;

    //make sure the date being entered isn't before the order date of this PO
    if (moment(orderDate).isAfter(date)) {
      tempState[_index][_name] = new Date();
    } else {
      tempState[_index][_name] = moment(date).toISOString();
    }

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const changePOLines_date = (date, _name) => {
    //check that our date is not before the order date
    const orderDate = poState.orderDate;

    //since we are doing a deep edit on the state
    //do the edit out side of state then reinject with a setFormState call
    let tempState = poState.lines;
    tempState.forEach((line, _lineIndex) => {
      //make sure the date being entered isn't before the order date of this PO
      if (moment(orderDate).isAfter(date)) {
        tempState[_lineIndex][_name] = new Date();
      } else {
        tempState[_lineIndex][_name] = moment(date).toISOString();
      }     
    });

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const changePOLine_checkbox = (e, _index) => {
    //make sure the shipped date is after or

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    //since we are doing a deep edit on the state
    //do the edit out side of state then reinject with a setFormState call
    let tempState = poState.lines;
    tempState[_index][e.target.name] = !poState.lines[_index][e.target.name];

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const onClickAddTracking = (e, _lineIndex, addItem) => {
    //ignore if we aren't adding a new tracking item/shipment
    if (!addItem) return;

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    let _qtyDefault =
      poState.lines[_lineIndex].qtyOrdered -
      poState.lines[_lineIndex].qtyReceived;

    //look through all the other tracking info and try to grab the number of items already shipped out
    poState.lines[_lineIndex]._trackingInfo.forEach((shipment) => {
      if (
        shipment === null ||
        shipment === undefined ||
        shipment.qtyShipped === null ||
        shipment.qtyShipped === undefined ||
        shipment.qtyShipped <= 0
      )
        return;

      _qtyDefault = _qtyDefault - shipment.qtyShipped;
    });

    if (_qtyDefault <= 0) _qtyDefault = 0;

    //add a tracking item to our array at the line level
    let tempState = poState.lines;
    tempState[_lineIndex].supplierShipped = true;
    tempState[_lineIndex]._trackingInfo.push({
      key: uuidv4(),
      shipDate: null,
      trackingNumber: "",
      carrier: "UPS Ground",
      qtyShipped: _qtyDefault, //grab how many items were ordered so we can default a full shipment
    });

    //if the line hasn't been confirmed then confirm the line
    if (!tempState[_lineIndex].acknowledged) {
      tempState[_lineIndex]._confirmedPrice = tempState[_lineIndex].unitPrice;
      tempState[_lineIndex]._confirmedQty = tempState[_lineIndex].qtyOrdered;
      tempState[_lineIndex].acknowledged = true;
      tempState[_lineIndex].acknowledgedDate = moment().toISOString();
    }

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const onClickRemoveTracking = (e, _lineIndex, _shipmentIndex) => {
    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    //add a tracking item to our array at the line level
    let tempState = poState.lines;

    //remove the item from the index
    tempState[_lineIndex]._trackingInfo.splice(_shipmentIndex, 1);

    //eval if we are still considering this item shipped
    if (tempState[_lineIndex]._trackingInfo.length === 0) {
      tempState[_lineIndex].supplierShipped = false;
    }

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const onChangeTrackingInfo = (e, _lineIndex, _shipmentIndex) => {
    if (e.target.name === "qtyShipped" && isNaN(e.target.value)) {
      setAlert("Only numbers allowed in quantity shipped field", "danger");
      return;
    }

    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    //add a tracking item to our array at the line level
    let tempState = poState.lines;
    tempState[_lineIndex]._trackingInfo[_shipmentIndex][e.target.name] =
      e.target.value;

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const onChangeTrackingInfo_date = (
    date,
    _lineIndex,
    _shipmentIndex,
    _name
  ) => {
    setSaveButtonState({ ...saveButtonState, display: true });

    //since we are doing a deep edit on the state
    //do the edit out side of state then reinject with a setFormState call
    let tempState = poState.lines;
    tempState[_lineIndex]._trackingInfo[_shipmentIndex][_name] =
      moment(date).toISOString();

    //set our ship date to null so we can detect every thing below
    tempState[_lineIndex].supplierShipDate = null;

    //eval what the latest ship date is out of the line
    tempState[_lineIndex]._trackingInfo.forEach((shipment) => {
      //grab the latest shipment date
      if (
        tempState[_lineIndex].supplierShipDate === undefined ||
        tempState[_lineIndex].supplierShipDate === null
      ) {
        tempState[_lineIndex].supplierShipDate = moment(
          shipment.shipDate
        ).toISOString();
      }

      //see if our date is larger than the one that has been saved
      if (
        moment(shipment.shipDate).isAfter(
          tempState[_lineIndex].supplierShipDate
        )
      ) {
        tempState[_lineIndex].supplierShipDate = moment(
          shipment.shipDate
        ).toISOString();
      }
    });

    setpoState({
      ...poState,
      lines: tempState,
    });
  };

  const onChangeEmailBuyerFields = (e) => {
    setEmailMsgState({
      ...emailMsgState,
      [e.target.name]: e.target.value,
    });
  };

  const closeEmailPopup = (e) => {
    //clear the old email fields if one exists
    clearEmailPOBuyer();

    //clear the local state
    setEmailMsgState({
      ...emailMsgState,
      displayPopup: false,
      subject: "",
      msg: "",
      errorMsg: "",
    });
  };

  const sendEmailFromPopup = (e) => {
    const { msg = "", subject = "", from = "" } = emailMsgState;

    if (
      from === null ||
      from === undefined ||
      from === "" ||
      subject === null ||
      subject === undefined ||
      subject === "" ||
      msg === undefined ||
      msg === null ||
      msg === ""
    ) {
      setEmailMsgState({
        ...emailMsgState,
        errorMsg:
          "All fields are required. Please fill out all fields and try again.",
      });
      return;
    }

    //send our email through REDUX action
    emailPOBuyer(poState, params, emailMsgState);
  };

  const onClickConfirmLine = (_lineIndex, checkboxState) => {
    _acknowledgeLine(_lineIndex, checkboxState);
    return;
  };

  const onClickApplyToAllLines = (_acknowledge, _supplierPoNo, _expectedShipDate) => {

    confirmAlert({
      title: 'Apply to all PO Lines?',
      message: 'Please ensure that you have reviewed each line and acknowledged the quantity and prices.',
      buttons: [
        {
          label: 'No',
          onClick: () => {}
        },
        {
          label: 'Yes',
          onClick: () => {

            //first go through and acknowledge each line only if the user has set the 
            //acknowlege to true
            poState.lines.forEach((line, _lineIndex) => {
              _acknowledgeLine(_lineIndex, _acknowledge);      
            });  

            if (_supplierPoNo != null) {
              changePOLines('supplierOrderNumber', _supplierPoNo);
            }

            //Next update the expected ship dates
            if (_expectedShipDate != null) {
              changePOLines_date(_expectedShipDate, 'expectedShipDate');
            }
          }
        }
      ]
    });

    return;
  };

  const _acknowledgeLine = (_lineIndex, checkboxState) => {
    setSaveButtonState({ ...saveButtonState, display: true });
    setFormState("modified");

    let tempState = poState.lines;
    tempState[_lineIndex]._confirmedPrice = tempState[_lineIndex].unitPrice;
    tempState[_lineIndex]._confirmedQty = tempState[_lineIndex].qtyOrdered;
    tempState[_lineIndex].acknowledged = checkboxState;
    tempState[_lineIndex].acknowledgedDate = moment().toISOString();

    setpoState({
      ...poState,
      lines: tempState,
    });
  };
  
  return (
    <div id="pageSupplierPO">
      <ChangePageTitle pageTitle={"Stellar PO# " + poState.poNo} />
      <div className="centerContainer">
        {poState === null ||
        poState.deleteFlag === null ||
        poState.deleteFlag === undefined ||
        poState.deleteFlag ? (
          <Fragment>
            <div
              className="contentBox"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              This PO is not valid
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {loading && (
              <Fragment>
                <div className="contentBox">
                  <Spinner />
                </div>
              </Fragment>
            )}

            {!loading && (
              <Fragment>
                {emailMsgState.displayPopup !== undefined &&
                  emailMsgState.displayPopup !== null &&
                  emailMsgState.displayPopup && (
                    <SupplierEmailPopup
                      supplierPO={supplierPO}
                      emailMsgState={emailMsgState}
                      poState={poState}
                      onChangeEmailBuyerFields={onChangeEmailBuyerFields}
                      closeEmailPopup={closeEmailPopup}
                      sendEmailFromPopup={sendEmailFromPopup}
                    />
                  )}
                
                  <div className="contentBox ackChecklist blue-line-top-border">
                    <AckChecklist poState={poState} />
                  </div>

                <div className="contentBox poHDR">
                  {saveButtonState.display && (
                    <SupplierSaveBar savePO={savePO} />
                  )}
                  <SupplierHDR
                    poState={poState}
                  />
                </div>
                <div className="contentBox poHdrShipTo">
                  <SupplierHdrShipping
                    poState={poState}
                    confirmShipToFunc={confirmShipToFunc}
                  />
                </div>
                <div className="contentBox items blue-line-bottom-border">
                  {saveButtonState.display && (
                    <SupplierSaveBar savePO={savePO} />
                  )}
                  <SupplierItems
                    poState={poState}
                    changePOLine={changePOLine}
                    changePOLine_date={changePOLine_date}                    
                    changePOLines_date={changePOLines_date}
                    changePOLine_checkbox={changePOLine_checkbox}
                    onClickAddTracking={onClickAddTracking}
                    onChangeTrackingInfo={onChangeTrackingInfo}
                    onChangeTrackingInfo_date={onChangeTrackingInfo_date}
                    onClickRemoveTracking={onClickRemoveTracking}
                    onClickConfirmLine={onClickConfirmLine}
                    onClickApplyToAllLines={onClickApplyToAllLines}
                  />
                </div>
              </Fragment>
            )}
            {!loading &&
              (savingToP21 ||
                (poState._updatingP21Flag !== null &&
                  poState._updatingP21Flag)) && (
                <Fragment>
                  <div className="savingOverlay">
                    <div className="statusMsg-outer">
                      <div className="statusMsg">
                        <Spinner />
                        <div className="title">
                          Please wait, saving Purchase Order details...
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

SupplierPO.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  params: ownProps.match.params,
  supplierPO: state.supplierpo,
});

export default connect(mapStateToProps, {
  getPODetails,
  savePODetails,
  setAlert,
  emailPOBuyer,
  clearEmailPOBuyer,
})(SupplierPO);
