import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";

const SupplierItemTracking = ({
  line = {},
  poState = {},
  lineIndex = 0,
  shipmentIndex = 0,
  shipment = {},
  addItem = false,
  onClickAddTracking,
  onChangeTrackingInfo,
  onChangeTrackingInfo_date,
  onClickRemoveTracking,
}) => {
  const {
    shipDate = new Date(),
    trackingNumber = "",
    carrier = "UPS Ground",
    qtyShipped = 0,
  } = shipment;

  const { UOM = "" } = line;

  return (
    <div
      className="trackingCard"
      onClick={(e) => onClickAddTracking(e, lineIndex, addItem)}
    >
      {addItem ? (
        <Fragment>
          <div className="addLink">Add Item Shipment Tracking Info</div>
        </Fragment>
      ) : (
        <Fragment>
          <div
            className="close"
            onClick={(e) => onClickRemoveTracking(e, lineIndex, shipmentIndex)}
          >
            <i className="fas fa-times-circle"></i>
          </div>
          <table width="100%">
            <tbody>
              <tr>
                <td>Shipped Date:</td>
                <td>
                  <div className="customDatePicker-outer">
                    <DatePicker
                      className="customDatePicker"
                      selected={
                        moment(shipDate).isValid()
                          ? new Date(moment(shipDate).format("MM/DD/YY"))
                          : null
                      }
                      dateFormat="MM/dd/yy"
                      closeOnScroll={true}
                      onChange={(date) =>
                        onChangeTrackingInfo_date(
                          date,
                          lineIndex,
                          shipmentIndex,
                          "shipDate"
                        )
                      }
                      minDate={(new Date(poState.orderDate))}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Carrier:</td>
                <td>
                  <select
                    name="carrier"
                    value={carrier}
                    onChange={(e) =>
                      onChangeTrackingInfo(e, lineIndex, shipmentIndex)
                    }
                  >
                    <option value="UPS Ground">UPS Ground</option>
                    <option value="UPS Orange">UPS Orange (3rd Day)</option>
                    <option value="UPS Blue">UPS Blue (2nd Day)</option>
                    <option value="UPS Red">UPS Red (Next Day)</option>
                    <option value="USF Reddaway">USF Reddaway</option>
                    <option value="UPS Freight">UPS Freight</option>
                    <option value="FEDEX Ground">FEDEX Ground</option>
                    <option value="FEDEX 3rd Day Air">FEDEX 3rd Day Air</option>
                    <option value="FEDEX 2nd Day">FEDEX 2nd Day</option>
                    <option value="FEDEX (Next Day)">FEDEX (Next Day)</option>
                    <option value="FEDEX Priority Overnight">
                      FEDEX Priority Overnight
                    </option>
                    <option value="FEDEX Freight">FEDEX Freight</option>
                    <option value="USPS">USPS</option>
                    <option value="Will Call">Will Call</option>
                    <option value="Best Way">Best Way</option>
                    <option value="DHL">DHL</option>
                    <option value="Oak Harbor Freight Lines">
                      Oak Harbor Freight Lines
                    </option>
                    <option value="Peninsula Freight Lines">
                      Peninsula Freight Lines
                    </option>
                    <option value="R & L Freight<">R & L Freight</option>
                    <option value="YRC">YRC (RDWY)</option>
                    <option value="Saia">Saia</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Tracking:</td>
                <td>
                  <input
                    type="text"
                    name="trackingNumber"
                    value={trackingNumber}
                    onChange={(e) =>
                      onChangeTrackingInfo(e, lineIndex, shipmentIndex)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Qty Shipped:</td>
                <td>
                  <input
                    type="text"
                    name="qtyShipped"
                    value={qtyShipped}
                    onChange={(e) =>
                      onChangeTrackingInfo(e, lineIndex, shipmentIndex)
                    }
                  />{" "}
                  <span className="trackingUOM">{UOM}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </Fragment>
      )}
    </div>
  );
};

SupplierItemTracking.propTypes = {
  line: PropTypes.object.isRequired,
  poState: PropTypes.object.isRequired,
  lineIndex: PropTypes.number.isRequired,
  shipmentIndex: PropTypes.number,
  shipment: PropTypes.object,
  addItem: PropTypes.bool.isRequired,
  onClickAddTracking: PropTypes.func.isRequired,
  onChangeTrackingInfo: PropTypes.func.isRequired,
  onChangeTrackingInfo_date: PropTypes.func.isRequired,
  onClickRemoveTracking: PropTypes.func.isRequired,
};

export default SupplierItemTracking;
