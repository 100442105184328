import React from "react";
import ChangePageTitle from "../../../layout/Common/ChangePageTitle";
import headerLogo from '../../../../assets/stellar-logo_header.png';

const spoxHelp = (props) => {
  return (
    <div>
      <ChangePageTitle pageTitle="Stellar SPOX Help" />
      <div className="centerContainer">
        <div className="contentBox blue-line-top-border">
          <div className='logo-outer'>
            <a href="https://www.stellarindustrial.com" target="_blank" rel="noopener noreferrer">
              <img src={headerLogo} alt='Stellar Industrial Supply' />
            </a>
          </div>
          <div className="padding-bottom-10"><sup>Version 3.0 - Compatible browsers: Microsoft Edge, Chrome, Firefox, or Safari (MacOS)</sup></div>
          <div className="bold font18 padding-bottom-10">Help Documentation</div>
          <div className="border-top-1">SPOX is our Stellar Industrial Purchase Order Expediting system.  This system is used to provide an easy interface for our suppliers to keep us up to date on
            purchase order acknowledgments, estimated ship dates, and actual shipping dates.
          </div>
        </div>

        <div className="contentBox" style={{ lineHeight: "1.5em" }}>
          <div className="font16 bold border-bottom-1 padding-bottom-10">Contacting the Buyer</div>
          <br />
          If you need to request changes, please click on the buyer's email
          address at the top of the PO. This will open a new email, using your
          default email client, and start an email to the Stellar Industrial buyer.
        </div>

        <div className="contentBox">
          <div className="font16 bold border-bottom-1 padding-bottom-10">
            Daily Email - Take Action - Coming Due and Past Due Purchase Orders
          </div>
          <br />
          <div>
            <b>1.</b> From the Coming Due and Past Due Purchaser Orders email please click the View link under Action to access the
            Purchase Order in the Stellar Industrial Supplier Portal.
            <br />
            <br />
            <b>2.</b> Estimaged Shipping Date – if the estimated shipping date has
            changed, enter the new estimated ship date in this field for each
            affected line item.
            <br />
            <br />
            <b>3.</b> Add Item Shipment Tracking Info – If the line item(s) have
            shipped, click the Add Item Shipment Tracking Info link to enter the
            Shipped Date, Carrier, Tracking, and Qty Shipped.
            <br />
            <br />
            <b>4.</b> Save Changes Button – When you have finished
            updating the Estimated Shipping Date(s) and/or entering Item Shipment
            Tracking Info, click the Save Changes button to send the
            information to Stellar’s ERP system.
          </div>
        </div>
      </div>
    </div>
  );
};

export default spoxHelp;
