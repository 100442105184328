import React from "react";
import PropTypes from "prop-types";
import { Fragment } from "react";

const Supplier_hdrShipping = ({ poState = {}, confirmShipToFunc }) => {
  const {
    mailName = "",
    mailAddress = "",
    mailAddress2 = "",
    mailAddress3 = "",
    mailCity = "",
    mailPostal = "",
    mailState = "",
    shippingInstructions = "",
    carrierName = "",
    externalPoNo,
    lines = [],
  } = poState;

  let _allConfirmed = true;

  //loop through all of our lines to see if all are acknowledged 
  lines.forEach((line) => {
    const {
      acknowledged = false,
    } = line;

    if (!acknowledged) _allConfirmed = false;
  });

  const sisurl = "https://www.stellarindustrial.com/SupplierTerms";
  return (
    <Fragment>
      <div className="title">Shipping Info</div>
        {poState.spoxEnabled && (
          <div className="shipToAgree">
            {!_allConfirmed && (
              <Fragment>
                <div>By acknowledging the lines on this Purchase Order you agree to the <a href={sisurl} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>, Ship To address, and Shipping Instructions.</div>
              </Fragment>
            )}
            {_allConfirmed && (
                <div>You have agreed to the <a href={sisurl} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>, Ship To address, and Shipping Instructions.</div>
            )}
          </div>
        )}
      <br/>
      <div className="flex">
        <div className="card">
        <div className="cardTitle">Ship To:</div>
          <div className="smallerLines">
            {mailName !== "" && mailName !== null && (
              <Fragment>
                {/* strip off the first 4 characters from branch because they start with 01- */}
                <b>{mailName.substring(mailName.indexOf("-")+1)}</b>
                <br />
              </Fragment>
            )}
            <b>{mailAddress}</b>
            <br />
            {mailAddress2 !== "" && mailAddress2 !== null && (
              <Fragment>
                <b>{mailAddress2}</b>
                <br />
              </Fragment>
            )}
            {mailAddress3 !== "" && mailAddress3 !== null && (
              <Fragment>
                {mailAddress3}
                <br />
              </Fragment>
            )}
            <b>{mailCity}, {mailState} {mailPostal}</b>
          </div>
        </div>
        <div className="card">
        <div className="cardTitle">Shipping Instructions:</div>
          <div>
            {carrierName !== "" && carrierName !== null && (
              <Fragment>
                <span>Carrier: <b>{carrierName}</b></span>
                <br />
              </Fragment>
            )}
            <span>Instructions: <b>{shippingInstructions}</b></span>
          </div>
        </div>
        {externalPoNo !== "" && externalPoNo !== null && (
          <div className="card">
          <div className="cardTitle">Customer PO No:</div>
            <div>
              <span>PO No: <b>{externalPoNo}</b></span>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

Supplier_hdrShipping.propTypes = {
  poState: PropTypes.object.isRequired,
  confirmShipToFunc: PropTypes.func.isRequired,
};

export default Supplier_hdrShipping;
