import React from "react";
import { Link } from "react-router-dom";

import PageHeading from "../../../layout/PageHeading/PageHeading";

import "./Dashboard.css";

const Dashboard = (props) => {
  return (
    <div id="employeeDashboardPage">
      <div className="centerContainer">
        <PageHeading
          config={{
            preHeading: "Stellar Industrial Supplier Portal",
            heading: "Supplier Dashboard",
            description: "",
            graphic: "fas fa-tachometer-alt",
          }}
        />

        <div className="contentBox dashboardSec1">
          <h3 style={{ marginTop: "0px" }}>Supplier Tools:</h3>
          <div className="flex">
            <div className="leftDiv">
              <div className="flexLinkContent">
                <div className="leftDiv">
                  <Link to="/expedite">
                    <i className="fas fa-truck fa-fw" />
                  </Link>
                </div>
                <div className="rightDiv"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*

            <div className='rightDiv'>
              <div className='flexLinkContent'>
                <div className='leftDiv'>
                  <Link to='/employee/prodfuzzymatch'>
                    <i className='fas fa-list-alt fa-fw' />
                  </Link>
                </div>
                <div className='rightDiv'>
                  <Link to='/employee/prodfuzzymatch'>
                    <h3>Product ID Helper</h3>
                    Search P21 parts using plain english
                  </Link>
                </div>
              </div>
            </div>
            */

Dashboard.propTypes = {};

export default Dashboard;
