import {
  SUPPO_LOADING,
  SUPPO_RESULT,
  SUPPO_LOADERROR,
  SUPPO_SAVING,
  SUPPO_SAVED,
  SUPPO_SAVEERROR,
  SUPPO_EMAILERROR,
  SUPPO_EMAILSUCCESS,
  SUPPO_EMAILSENDING,
  SUPPO_EMAILCLEAR,
} from "../actions/types";

const initialState = {
  loading: true,
  loadError: false,
  saveError: false,
  saveComplete: false,
  poData: {},
  savingToP21: false,
  emailSending: false,
  emailSent: false,
  emailError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUPPO_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        saveComplete: false,
        savingToP21: false,
        emailSending: false,
      };
    case SUPPO_RESULT:
      return {
        ...state,
        loading: false,
        saveError: false,
        saveComplete: false,
        poData: payload,
        savingToP21: false,
        emailSending: false,
        emailSent: false,
        emailError: false,
      };
    case SUPPO_LOADERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        savingToP21: false,
        emailSending: false,
      };
    case SUPPO_SAVING:
      return {
        ...state,
        savingToP21: true,
      };
    case SUPPO_SAVED:
        return {
          ...state,
          savingToP21: false,
          saveComplete: true
        };
    case SUPPO_SAVEERROR:
      return {
        ...state,
        loading: false,
        loadError: false,
        saveError: true,
        saveComplete: true,
        savingToP21: false
      };
    case SUPPO_EMAILSENDING:
      return {
        ...state,
        emailSending: true,
        emailSent: false,
        emailError: false,
      };
    case SUPPO_EMAILERROR:
      return {
        ...state,
        emailSending: false,
        emailSent: false,
        emailError: true,
      };
    case SUPPO_EMAILSUCCESS:
      return {
        ...state,
        emailSending: false,
        emailSent: true,
        emailError: false,
      };
    case SUPPO_EMAILCLEAR:
      return {
        ...state,
        emailSending: false,
        emailSent: false,
        emailError: false,
      };
    default:
      return state;
  }
}
