import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

const SupplierHDR = ({ poState = {} }) => {
  const {
    poNo = 0,
    buyerName = "",
    buyerPhone = "",
    buyerEmail = "",
    supplierName = "",
    supplierID = 0,
    orderDate = null,
    poPdf = "",
    lines = [],
  } = poState;

  /* eslint-disable no-unused-vars */
  let countAll = 0,
    countConfirmed = 0,
    countShipped = 0,
    countPartial = 0,
    countCompleted = 0;

  //count each category so we can display
  if (Array.isArray(lines) && lines.length > 0) {
    lines.forEach((line) => {
      const {
        remainingQty = 0,
        qtyReceived = 0,
        supplierShipped = false,
        acknowledged = false,
        complete = false,
        deleteFlag = false,
      } = line;

      if (deleteFlag) {
        return;
      }

      //add to all
      countAll++;

      if (complete) {
        countCompleted++;
      }

      if (qtyReceived > 0 && remainingQty > 0) {
        countPartial++;
      }

      if (supplierShipped) {
        countShipped++;
      }
      if (acknowledged) {
        countConfirmed++;
      }
    });
  }

  return (
    <Fragment>
      {(poPdf !== null && poPdf !== "") && (
        <Fragment>
          <div id="downloadIcon" className="font-size-18 padding-bottom-10 border-bottom margin-bottom-10 fontWeightBold">
            <Link onClick={() => {

                let pdf = {
                  file: `data:application/pdf;base64,${poPdf}`,
                  file_name: `Stellar_PO_${poNo}`
                }

                //trigger an automatic download of the file
                const pdfLink = `${pdf.file}`;
                const anchorElement = document.createElement('a');
                const fileName = `${pdf.file_name}.pdf`;
                anchorElement.href = pdfLink;
                anchorElement.download = fileName;
                anchorElement.click();
              }}>
            <span>Download<i className="padding-left-5 fa fa-download"></i></span>
            </Link>
          </div>
        </Fragment>
      )}

      <div className="title">Purchase Order No: {poNo}</div>
      <div>Issued To: <b>{supplierName} ({supplierID})</b></div>
      <div className="subTitle">
        PO Date:{" "}
        <span><b>{orderDate !== null &&
          moment(orderDate).isValid &&
          moment(orderDate).format("MM/DD/YYYY")}</b></span>
      </div>
      <div className="flex hdrCols">
        <div className="card">
          <div className="cardTitle">Bill To:</div>
          <b>Stellar Industrial Supply</b>
          <div>
            711 E 11th Street
            <br />
            Tacoma, WA 98421
          </div>
        </div>

        <div className="card">
          <div className="cardTitle">Buyer:</div>
          <div>
            <b>{buyerName}</b>
            <br />
            {buyerPhone}
            <br />
            <a href={"mailto:" + buyerEmail + "?subject=Inquiry about PO# " + poNo}>{buyerEmail}</a>
          </div>
        </div>

        <div className="card">
          <div className="cardTitle">Send Invoices To:</div>
          <div>
            <b>Email</b>: apinvoice@stellarindustrial.com
            <br />
            Fax: 253-593-1228
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SupplierHDR.propTypes = {
  poState: PropTypes.object.isRequired,
};

export default SupplierHDR;
