import React from "react";
import PropTypes from "prop-types";

const Supplier_saveBar = ({ savePO }) => {
  return (
    <button className="saveButton" onClick={(e) => savePO()}>Save Changes</button>
  );
};

Supplier_saveBar.propTypes = {
  savePO: PropTypes.func.isRequired,
};

export default Supplier_saveBar;
