import React, { Fragment } from "react";
import PropTypes from "prop-types";
import headerLogo from './stellar-logo-header.png';
import { Link } from "react-router-dom";

const Landing = ({ poState = {} }) => {
  const {
    _agreedToTerms = false,
    _shipToConfirmed = false,
    lines = [],
    spoxEnabled = false,
  } = poState;

  /* eslint-disable no-unused-vars */
  let _allConfirmed = true,
    _allShipped = true,
    _allReceived = true,
    countAll = 0,
    countUnconfirmed = 0,
    countConfirmed = 0,
    countShipped = 0,
    countPartial = 0,
    countCompleted = 0;

  //loop through all of our lines
  lines.forEach((line) => {
    const {
      remainingQty = 0,
      qtyReceived = 0,
      supplierShipped = false,
      acknowledged = false,
      complete = false,
      deleteFlag = false,
    } = line;

    if (deleteFlag) {
      return;
    }

    if (!acknowledged) _allConfirmed = false;
    if (!supplierShipped) _allShipped = false;
    if (!complete) _allReceived = false;

    //add to all
    countAll++;

    if (complete) {
      countCompleted++;
      return;
    }

    if (qtyReceived > 0 && remainingQty > 0) {
      countPartial++;
    }

    if (supplierShipped) {
      countShipped++;
    }
    if (acknowledged) {
      countConfirmed++;
    } else {
      countUnconfirmed++;
    }
  });

  return (
    <Fragment>
      <div className="spox_help-outer">
        <div className="spox_help-inner">
          <Link
            to={spoxEnabled ? "/spoxHelp" : "/nonSpoxHelp"}
            target="_blank"
            rel="noopener noreferrer"
          >
           Need Help?
          </Link>
        </div>
      </div>
      <div className='logo-outer'>
        <a href="https://www.stellarindustrial.com" target="_blank" rel="noopener noreferrer">
          <img src={headerLogo} alt='Stellar Industrial Supply' />
        </a>
      </div>

      <div className="flex checklist">
        {(spoxEnabled) && (
          <Fragment>
          <div className={_shipToConfirmed ? "card complete" : "card"}>
            <i className="far fa-check-circle"></i>
            Confirm Shipping
            <br />
            Info & Instructions
          </div>
          
          <div className={_agreedToTerms ? "card complete" : "card"}>
            <i className="far fa-check-circle"></i>
            Read & Agree To
            <br />
            Terms & Conditions
          </div>
          <div className={_allConfirmed ? "card complete" : "card"}>
            <i className="far fa-check-circle"></i>
            Acknowledge All
            <br />
            PO Line Items
          </div>
          </Fragment>
        )}

        <div className={_allShipped ? "card complete" : "card"}>
          <i className="far fa-check-circle"></i>
          Ship All PO
          <br />
          Line Items
        </div>
        <div className={_allReceived ? "card complete" : "card"}>
          <i className="far fa-check-circle"></i>
          All PO Lines
          <br />
          Received In Full
        </div>
      </div>
      <div className="filters-outer">
        <span>All ({countAll}) </span>|
        {(spoxEnabled) && (
          <Fragment>
            <span>Acknowledged ({countConfirmed})</span>|
          </Fragment>
        )}
        <span>Shipped ({countShipped})</span>|
        <span>Partial Received ({countPartial})</span>|
        <span>Completed ({countCompleted})</span>
      </div>
    </Fragment>
  );
};

Landing.propTypes = {
  poState: PropTypes.object.isRequired,
};

export default Landing;
