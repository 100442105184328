export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//User Management Types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";

//LAYOUT Types
export const SIDEDRAWER_OPEN = "SIDEDRAWER_OPEN";
export const SIDEDRAWER_CLOSE = "SIDEDRAWER_CLOSE";
export const BACKDROP_SHOW = "BACKDROP_SHOW";
export const BACKDROP_HIDE = "BACKDROP_HIDE";
export const ASYOUTYPE_SHOW = "ASYOUTYPE_SHOW";
export const ASYOUTYPE_HIDE = "ASYOUTYPE_HIDE";
export const RESET_NAV = "RESET_NAV";
export const NAVDROPDOWN_SHOW = "NAVDROPDOWN_SHOW";
export const NAVDROPDOWN_HIDE = "NAVDROPDOWN_HIDE";

//Supplier PO Types
export const SUPPO_LOADING = "SUPPO_LOADING";
export const SUPPO_RESULT = "SUPPO_RESULT";
export const SUPPO_LOADERROR = "SUPPO_LOADERROR";
export const SUPPO_SAVING = "SUPPO_SAVING";
export const SUPPO_SAVED = "SUPPO_SAVED";
export const SUPPO_SAVEERROR = "SUPPO_SAVEERROR";
export const SUPPO_EMAILERROR = "SUPPO_EMAILERROR";
export const SUPPO_EMAILSENDING = "SUPPO_EMAILSENDING";
export const SUPPO_EMAILSUCCESS = "SUPPO_EMAILSUCCESS";
export const SUPPO_EMAILCLEAR = "SUPPO_EMAILCLEAR";
