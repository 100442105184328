import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import SupplierItem from "./SupplierItem";
import {useState} from 'react';

const Items = ({
  poState = {},
  changePOLine,
  changePOLine_date,
  changePOLine_checkbox,
  onClickAddTracking,
  onChangeTrackingInfo,
  onChangeTrackingInfo_date,
  onClickRemoveTracking,
  onClickConfirmLine,
  onClickApplyToAllLines
}) => {
  const { spoxEnabled = false, lines = [] } = poState;
  const [overallAck, setAck] = useState();
  const [overallExpShipDate, setDate] = useState();
  const [overallSupplierOrderNo, setOrderNo] = useState("");
  let _allConfirmed = true;

  //loop through all of our lines to see if all are acknowledged 
  lines.forEach((line) => {
    const {
      acknowledged = false,
    } = line;

    if (!acknowledged) _allConfirmed = false;
  });

  return (

    <Fragment>
      <div className="outer-flex">
        <div className="title">Purchase Order Lines</div>
      </div>
      <div className="margin-bottom-5"><b>Fast Update</b> - These options allow you to change values on all lines at one time.</div>
      <div className="overall-lines-flex margin-left-10">
        <div>
          <span>Supplier Order No:</span>{" "}
          <input
            className="supplierOrderNo"
            type="text"
            name="overallSupplierOrderNumber"
            value={overallSupplierOrderNo}
            onChange={(e) => setOrderNo(e.target.value)}
          />
        </div>
        <div>
          <span>Estimated Ship Date: </span>
          <Fragment>
            <div className="customDatePicker-outer">
              <DatePicker
                className="customDatePicker"
                dateFormat="MM/dd/yyyy"
                closeOnScroll={true}
                selected={overallExpShipDate}
                onChange={(overallExpShipDate) => setDate(overallExpShipDate)}
                minDate={(new Date(poState.orderDate))}
              />

            </div>
          </Fragment>
        </div>
        {spoxEnabled &&
          <Fragment>
            <div class="boxes ackLineOptions">
              <input type="checkbox" className="styled-checkbox-important"
                id="box-2"
                name="_acknowledgeAll"
                onClick={(e) => setAck(e.target.checked)}
              />
              <label for="box-2">Acknowledge Line</label>
            </div>
          </Fragment>
        }
        <div>
          <button className="button"
          onClick={() => onClickApplyToAllLines(overallAck, overallSupplierOrderNo, overallExpShipDate)}
          >Apply to Lines</button>
        </div>
      </div>
      {lines.map((line, index) => {
        const { poLineUID = 0 } = line;
        return (
          <SupplierItem
            line={line}
            poState={poState}
            index={index}
            changePOLine={changePOLine}
            changePOLine_date={changePOLine_date}
            changePOLine_checkbox={changePOLine_checkbox}
            key={poLineUID}
            onClickAddTracking={onClickAddTracking}
            onChangeTrackingInfo={onChangeTrackingInfo}
            onChangeTrackingInfo_date={onChangeTrackingInfo_date}
            onClickRemoveTracking={onClickRemoveTracking}
            onClickConfirmLine={onClickConfirmLine}
          />
        );
      })}
    </Fragment>
  );
};

Items.propTypes = {
  poState: PropTypes.object.isRequired,
  changePOLine: PropTypes.func.isRequired,
  changePOLine_date: PropTypes.func.isRequired,
  changePOLines_date: PropTypes.func.isRequired,
  changePOLine_checkbox: PropTypes.func.isRequired,
  onClickAddTracking: PropTypes.func.isRequired,
  onChangeTrackingInfo: PropTypes.func.isRequired,
  onChangeTrackingInfo_date: PropTypes.func.isRequired,
  onClickRemoveTracking: PropTypes.func.isRequired,
  onClickConfirmLine: PropTypes.func.isRequired,
  onClickApplyToAllLines: PropTypes.func.isRequired
};

export default Items;
