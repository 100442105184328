import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  SUPPO_LOADING,
  SUPPO_RESULT,
  SUPPO_LOADERROR,
  SUPPO_SAVING,
  SUPPO_SAVED,
  SUPPO_SAVEERROR,
  SUPPO_EMAILERROR,
  SUPPO_EMAILSUCCESS,
  SUPPO_EMAILSENDING,
  SUPPO_EMAILCLEAR,
} from "./types";


//Get the supplierPO information for the PO passed in with the public URL code.
/**
 * This retrieves the PO for the params passed in.
 * @param {*} params 
 * @param {*} intervalCheck 
 * @returns 
 */
export const getPODetails =
  (params, intervalCheck = false) =>
  async (dispatch) => {
    try {
      if (intervalCheck) dispatch({ type: SUPPO_LOADING });
      const {
        _id = "",
        _publicAccessCode = "",
        poNo = 0,
        supplierID = 0,
      } = params;

      if (
        _id === null ||
        _id === "" ||
        _publicAccessCode === null ||
        _publicAccessCode === "" ||
        poNo === null ||
        poNo <= 0 ||
        supplierID === null ||
        supplierID <= 0
      ) {
        console.error('getPODetails - PO load failed, invalid parameters.', params);
        dispatch({ type: SUPPO_LOADERROR });
        return;
      }

      const res = await axios.get(
        `/api/supplierPO/${_id}/${_publicAccessCode}/${poNo}/${supplierID}`
      );

      dispatch({ type: SUPPO_RESULT, payload: res.data });
    } catch (error) {
      console.error('getPODetails - PO load failed - ' + error.message);
      dispatch({ type: SUPPO_LOADERROR });
    }
  };

/**
 * Save the PO back to P21
 * @param {*} poState 
 * @param {*} params 
 * @returns 
 */
export const savePODetails = (poState, params) => async (dispatch) => {
  try {
    dispatch({ type: SUPPO_SAVING });

    const {
      _id = "",
      _publicAccessCode = "",
      poNo = 0,
      supplierID = 0,
    } = params;

    if (
      _id === null ||
      _id === "" ||
      _publicAccessCode === null ||
      _publicAccessCode === "" ||
      poNo === null ||
      poNo <= 0 ||
      supplierID === null ||
      supplierID <= 0
    ) {
      console.error('savePODetails - PO load failed, invalid parameters.', params);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      `/api/supplierPO/${_id}/${_publicAccessCode}/${poNo}/${supplierID}`,
      poState,
      config
    );

    if (res.status === 200) {
      dispatch({ type: SUPPO_SAVED });
    }
    else {
      throw Error(res.statusText);
    }
  } catch (err) {
    let errMsg = 'savePODetails - PO save failed: ' + err.message;
    console.error(errMsg);
    dispatch({ type: SUPPO_SAVEERROR });
  }
};

/**
 * Send the buy an email for this PO
 * @param {*} poState 
 * @param {*} params 
 * @param {*} emailMsgState 
 * @returns 
 */
export const emailPOBuyer =
  (poState, params, emailMsgState) => async (dispatch) => {
    try {
      const {
        _id = "",
        _publicAccessCode = "",
        poNo = 0,
        supplierID = 0,
      } = params;
      const { from = "", subject = "", msg = "" } = emailMsgState;

      dispatch({ type: SUPPO_EMAILSENDING });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `/api/supplierPO/email/${_id}/${_publicAccessCode}/${poNo}/${supplierID}`,
        {
          from,
          msg,
          subject,
        },
        config
      );

      if (res.data.success) {
        dispatch({ type: SUPPO_EMAILSUCCESS });
        return;
      } else {
        dispatch({ type: SUPPO_EMAILERROR });
      }
    } catch (err) {
      console.log(err);
      //display an alert that there was a problem
      dispatch({ type: SUPPO_EMAILERROR });
    }
  };

export const clearEmailPOBuyer = () => async (dispatch) => {
  dispatch({ type: SUPPO_EMAILCLEAR });
  return;
};
