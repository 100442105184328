import React from "react";
import headerLogo from '../../../../assets/stellar-logo_header.png';
import ChangePageTitle from "../../../layout/Common/ChangePageTitle";

const spoxHelp = (props) => {
  return (
    <div>
      <ChangePageTitle pageTitle="Stellar SPOX Help" />
      <div className="centerContainer">

        <div className="contentBox blue-line-top-border">
          <div className='logo-outer'>
            <a href="https://www.stellarindustrial.com" target="_blank" rel="noopener noreferrer">
              <img src={headerLogo} alt='Stellar Industrial Supply' />
            </a>
          </div>
          <div className="padding-bottom-10"><sup>Version 3.0 - Compatible browsers: Microsoft Edge, Chrome, Firefox, or Safari (MacOS)</sup></div>
          <div className="bold font18 padding-bottom-10">Help Documentation</div>
          <div className="border-top-1">SPOX is our Stellar Industrial Purchase Order Expediting system.  This system is used to provide an easy interface for our suppliers to keep us up to date on
            purchase order acknowledgments, estimated ship dates, and actual shipping dates.
          </div>
        </div>

        <div className="contentBox" style={{ lineHeight: "1.5em" }}>
          <div className="font16 bold border-bottom-1 padding-bottom-10">Contacting the Buyer</div>
          <br />
          If you need to request changes, please click on the buyer's email
          address at the top of the PO.  This will open a new email, using your
          default email client, and start an email to the Stellar Industrial buyer.
        </div>

        <div className="contentBox" style={{ lineHeight: "1.5em" }}>
          <div className="font16 bold border-bottom-1 padding-bottom-10">Purchase Orders - Email Notifications</div>
          <br />
          <div>
            All communication from Stellar for purchase orders will come via an email from <b>do-not-reply@stellarindustrial.com</b>.  The email subject will start with one of the following phrases:
            <ul>
              <li><b>New PO</b> - A email where the subject starts with "New PO" is a newly issued PO to your company that needs acknowledgment.</li>
              <li><b>Revised PO</b> - An email where the subject starts with "Revised PO" is a PO, previously issued to your company, that has been changed and needs your review.</li>
              <li><b>Copy/Resend PO</b> - An email where the subject starts "Copy / Resend PO" is a PO, previously issued to your company, that has been resent via email. This typically happens if you never recieved it, and we need to resend it again.</li>
            </ul>
          </div>
          <br />
        </div>

        <div className="contentBox" style={{ lineHeight: "1.5em" }}>
          <div className="font16 bold border-bottom-1 padding-bottom-10">Downloading a Hard Copy PDF</div>
          <br />
          <div>
            If you require a hard copy PDF file of the Purchase Order, you can click on the Download link at the top of the PO.
            This will trigger an immediate download of the PO with the name Stellar_PO_[PONumber].pdf. The browser will download this 
            to the folder designated as your Browser Download folder on your computer.
          </div>
          <br />
        </div>

        <div className="contentBox" style={{ lineHeight: "1.5em" }}>
          <div className="font16 bold border-bottom-1 padding-bottom-10">Acknowledging and Setting Expected Ship Dates</div>
          <br />
          <div>
            <p><b>1.</b> From the New PO email, or Revised PO email, please click the
              green button labled "Click here to update the Purchase Order".  This will automatically take you into the Purchase Order for you to update the information.
            </p>
            <p><b>2.</b> Shipping Info – In this section click the checkbox to agree to the shipping address, instructions, and Supplier Terms and Conditions.
            </p>
            <p><b>4.</b> Purchase Order Lines – In this section, enter the Sales
              Order Number and Estimated Shipping Date and check the Acknowledge
              checkbox to acknowledge the line item.
            </p>
            <p><b>5.</b> Save Changes Button - When you have finished
              reviewing and acknowledging the line items, entered the estimated
              shipping dates, and sales order information, click the Save
              Changes button to send the information to Stellar’s ERP system.
              This acknowledges that you accept the quantity and pricing shown on the
              purchase order.
            </p>
          </div>
          <br />
        </div>

        <div className="contentBox" style={{ lineHeight: "1.5em" }}>
          <div className="font16 bold  border-bottom-1 padding-bottom-10">Daily Email - Take Action - Unacknowledged and At Risk Purchase Orders</div>
          <br />
          <p>Your company will recieve a daily email requesting action if your company has any Purchase Orders that have not been acknowledged, 
            company due in the next 7 days, or are past due.  You can view, and update, all the purchase orders from this email.</p>
          <div>
            <b>1.</b> From the Unacknowledged and At Risk Purchase Orders email, please click each view
            link to return to the Purchase Order in the Stellar Industrial Supplier Portal.
            <br />
            <br />
            <b>2.</b> Estimated Ship Date – if the estimated shipping date has
            changed since acknowledging the purchase order, you may enter the new
            estimated ship date here.
            <br />
            <br />
            <b>3.</b> Add Item Shipment Tracking Info – If the line item(s) have
            shipped, click the Add Item Shipment Tracking Info link to enter the
            Shipped Date, Carrier, Tracking, and Qty Shipped.
            <br />
            <br />
            <b>4.</b> Save Changes Button – When you have finished
            updating the Estimated Ship Date and/or entering Item Shipment
            Tracking Info, click the Save Changes button to send the
            information to Stellar’s ERP system.
          </div>
        </div>
      </div>
    </div >
  );
};

export default spoxHelp;
