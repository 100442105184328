import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../../../actions/auth";

import PageHeading from "../../../layout/PageHeading/PageHeading";
import "./Login.css";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    //grab our current cart of items
    //send it to be assimilated
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/supplier/dashboard" />;
  }

  return (
    <div className="loginPage">
      <div className="centerContainer">
        <PageHeading
          config={{
            preHeading: "Authorized Access Only",
            heading: "Supplier Login",
            description: "Enter your credentials below and press login.",
            graphic: "fas fa-sign-in-alt",
          }}
        />
        <div className="login_box contentBox">
          <form className="form" onSubmit={(e) => onSubmit(e)}>
            <div className="emailDiv">
              Username
              <br />
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="passwordDiv">
              Password
              <br />
              <input
                type="password"
                name="password"
                minLength="6"
                value={password}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="actionBtns">
              <input type="submit" id="loginButton" value="Login" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

/*
<div className='getStarted'>
            Don't Have an Account?
            <br />
            <Link to='/register'>
              <button className='getStartedBtn'>Get Started</button>
            </Link>
          </div>
          */

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
