import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./components/utils/setAuthToken";
import PrivateRoute from "./components/utils/PrivateRoute";

import "./App.css";
import "./TableDisplay.css";
import "./ContentBlock.css";

//Temporary Tools
//import TempTools from './components/pages/employeeOnly/tempTools/TempTools';

//Layout Components
import Backdrop from "./components/layout/Backdrop/Backdrop";

// Brett - Commented out until future use when we allow suppliers to login to the portal
// import SideBar from "./components/layout/SideBar/SideBar";
import ScrollToTop from "./components/layout/ScrollToTop/ScrollToTop";
import Alert from "./components/layout/Alert";

//Pages
import Dashboard from "./components/pages/supplierOnly/Dashboard/Dashboard";
import Login from "./components/pages/public/auth/Login";
import SupplierPO from "./components/pages/public/supplierPO/SupplierPO";
import SpoxHelp from "./components/pages/public/spoxHelp/spoxHelp";
import NonSpoxHelp from "./components/pages/public/nonSpoxHelp/nonSpoxHelp";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

// Check if user is logged in
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  //enable Application Insights to send telemetry information to Azure application insights
  const browserHistory = createBrowserHistory({ basename: '' });
  var reactPlugin = new ReactPlugin();
  var appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '462f8b10-6756-4b05-9573-18b55e03b667',
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });

  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((telemetryItem) => {
    telemetryItem.tags['ai.cloud.role'] = 'SPOX Portal';
  });

  useEffect(() => {
    //Brett - Disabled to prevent an unauthorized error when viewing the PO.
    //Will address later if we ever do a true portal with authentication
    //store.dispatch(loadUser());
  }, []);


  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  let backdrop;

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          {backdrop}
          <ScrollToTop />
          {/* Brett - Commented out until future use when we allow suppliers to login to the portal */}
          {/* <SideBar /> */}
          {/* <SideDrawer show={sideDrawerOpen} click={backdropClickHandler} />
          <Header drawerClick={toggleDrawerClickHandler} /> */}
          <Alert />
          <div id="contentWrapper">
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute
                exact
                path="/supplier/dashboard"
                component={Dashboard}
              />
              <Route exact path="/spoxhelp" component={SpoxHelp} />
              <Route exact path="/nonspoxhelp" component={NonSpoxHelp} />
              <Route
                exact
                path="/supplierPO_Remote/:_id/:_publicAccessCode/:poNo/:supplierID"
                component={SupplierPO}
              />
            </Switch>
          </div>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;
