import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './PageHeading.css';
const PageHeading = ({
  config: { preHeading = '', heading = '', description = '', graphic = '' },
}) => {
  return (
    <Fragment>
      <div className='pageHeadingDiv'>
        <div className='pageHeadingTop'>
          <div className='pageHeadingGraphic'>
            <i className={graphic}></i>
          </div>
          <div className='pageHeadingText-outer'>
            <div className='pagePreHeading'>{preHeading}</div>
            <div className='pageHeading'>{heading}</div>
          </div>
        </div>
        <div className='pageHeadingBottom'>{description}</div>
      </div>
    </Fragment>
  );
};

PageHeading.propTypes = {
  config: PropTypes.object.isRequired,
};

export default PageHeading;
